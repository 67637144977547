// @ts-nocheck

import { environment } from '@environments/environment';

// Define dataLayer and the gtag function.
window.dataLayer = window.dataLayer || [];

function gtag(){
  window.dataLayer.push(arguments);
}

window.gtag = gtag;

// Default ad_storage to 'denied'.
(gtag as any)('consent', 'default', {
  'analytics_storage': 'denied',
  'ad_storage': 'denied'
});

window.dataLayer.push({
  siteName: environment.project,
  siteEnvironment: environment.envDomain === 'development' ? 'development' : 'production',
});

// Load the Google Tag Manager script.
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-K3NSQW7');
